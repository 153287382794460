import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { Layout } from '../components/Layout';
import Section from '../components/Layout/Section';
import SEO from '../meta/SEO';

function NotFoundPage() {
    return (
        <Layout>
            <SEO />
            <Section>
                <Box w={'100%'}>
                    <Text fontSize={'4xl'}>{'Content Not Found'}</Text>
                    <Text>{'HTTP 404'}</Text>
                </Box>
            </Section>
        </Layout>
    );
}

export default NotFoundPage;
